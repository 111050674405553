<template>
  <div class="content">
    <!-- 顶部导航开始 -->
    <div class="top">
      <!-- 顶部导航图片开始 -->
      <img src="../assets/img/about-top-bg.png" alt="" />
      <!-- 顶部导航图片结束 -->
      <!-- 内容容器开始 -->
      <div class="container top-nav">
        <!-- 顶部导航开始 -->
        <my-header class="hidden-xs-only" active="1"></my-header>
        <language-change2
          class="hidden-sm-and-up"
          active="1"
        ></language-change2>
        <!-- 顶部导航结束 -->
      </div>
      <!-- 内容容器结束 -->
    </div>
    <!-- 顶部导航结束 -->
    <!-- 武汉博览中心开始 -->
    <div
      class="middle hidden-xs-only"
      v-if="titleList && titleList.length != 0"
    >
      <!-- 内容容器开始 -->
      <div class="container">
        <!-- 标题部分开始 -->
        <div class="title">
          <div v-show="language == 'cn'">{{ titleList.title }}</div>
          <div v-show="language == 'en'">{{ titleList.enTitle }}</div>
          <span class="small"></span>
          <span class="big"></span>
        </div>
        <!-- 标题部分结束 -->
        <!-- 内容开始 -->
        <div class="middle-content">
          <div class="flex justify-between">
            <div class="middle-content-left">
              <div v-show="language == 'cn'">{{ titleList.content }}</div>
              <div v-show="language == 'en'">{{ titleList.enCon1 }}</div>
            </div>
            <div class="middle-content-right">
              <img :src="titleList.img" alt="" />
            </div>
          </div>
          <div class="middle-content-left2" v-show="language == 'en'">
            {{ titleList.enCon2 }}
          </div>
        </div>
        <!-- 内容结束 -->
      </div>
      <!-- 内容容器结束 -->
    </div>
    <div
      class="middle hidden-sm-and-up"
      v-if="titleList && titleList.length != 0"
    >
      <!-- 内容容器开始 -->
      <div class="container">
        <!-- 标题部分开始 -->
        <div class="title">
          <div v-show="language == 'cn'">{{ titleList.title }}</div>
          <div v-show="language == 'en'">{{ titleList.enTitle }}</div>
          <span class="small"></span>
          <span class="big"></span>
        </div>
        <!-- 标题部分结束 -->
        <!-- 内容开始 -->
        <div class="">
          <div class="sm-msg">
            <div v-show="language == 'cn'">{{ titleList.content }}</div>
            <div v-show="language == 'en'">{{ titleList.enContent }}</div>
          </div>
          <div class="sm-img">
            <img :src="titleList.img" alt="" />
          </div>
        </div>
        <!-- 内容结束 -->
      </div>
      <!-- 内容容器结束 -->
    </div>
    <div class="middle2 hidden-xs-only">
      <!-- 内容容器开始 -->
      <div class="container">
        <div class="middle2-content">
          <!-- 单一内容开始 -->
          <div class="middle2-item align-center">
            <div class="middle2-item-msg1">
              <span class="number">12</span>
              <span class="unit" v-show="language == 'cn'">个</span>
            </div>
            <div class="middle2-item-msg2">
              <span v-show="language == 'cn'">展馆数量</span>
              <span v-show="language == 'en'">pavilions</span>
            </div>
          </div>
          <!-- 单一内容结束 -->
          <!-- 单一内容开始 -->
          <div class="middle2-item align-center">
            <div class="middle2-item-msg1" v-show="language == 'cn'">
              <span class="number">15</span>
              <span class="unit">万平方米</span>
            </div>
            <div class="middle2-item-msg1" v-show="language == 'en'">
              <span class="number">150</span>
              <span class="unit">K㎡</span>
            </div>
            <div class="middle2-item-msg2">
              <span v-show="language == 'cn'">室内展览面积</span>
              <span v-show="language == 'en'">indoor exhibition area</span>
            </div>
          </div>
          <!-- 单一内容结束 -->
          <!-- 单一内容开始 -->
          <div class="middle2-item align-center">
            <div class="middle2-item-msg1" v-show="language == 'cn'">
              <span class="number">4</span>
              <span class="unit">万平方米</span>
            </div>
            <div class="middle2-item-msg1" v-show="language == 'en'">
              <span class="number">40</span>
              <span class="unit">K㎡</span>
            </div>
            <div class="middle2-item-msg2">
              <span v-show="language == 'cn'">室外广场展览面积</span>
              <span v-show="language == 'en'"
                >outdoor plaza exhibition area</span
              >
            </div>
          </div>
          <!-- 单一内容结束 -->
          <!-- 单一内容开始 -->
          <div class="middle2-item align-center">
            <div class="middle2-item-msg1">
              <span class="number">6880</span>
              <span class="unit" v-show="language == 'cn'">个</span>
            </div>
            <div class="middle2-item-msg2">
              <span v-show="language == 'cn'">最多可提供标准展位</span>
              <span v-show="language == 'en'">can be provided</span>
            </div>
          </div>
          <!-- 单一内容结束 -->
        </div>
      </div>
      <!-- 内容容器结束 -->
    </div>
    <div class="middle2-sm hidden-sm-and-up">
      <!-- 内容容器开始 -->
      <div class="container">
        <div class="middle2-content-sm">
          <!-- 单一内容开始 -->
          <div class="middle2-item-sm">
            <div class="middle2-item-msg1-sm">
              <span class="number">12</span>
              <span class="unit" v-show="language == 'cn'">个</span>
            </div>
            <div class="middle2-item-msg2-sm">
              <span v-show="language == 'cn'">展馆数量</span>
              <span v-show="language == 'en'">pavilions</span>
            </div>
          </div>
          <!-- 单一内容结束 -->
          <!-- 单一内容开始 -->
          <div class="middle2-item-sm">
            <div class="middle2-item-msg1-sm" v-show="language == 'cn'">
              <span class="number">15</span>
              <span class="unit">万平方米</span>
            </div>
            <div class="middle2-item-msg1-sm" v-show="language == 'en'">
              <span class="number">150</span>
              <span class="unit">K㎡</span>
            </div>
            <div class="middle2-item-msg2-sm">
              <span v-show="language == 'cn'">室内展览面积</span>
              <span v-show="language == 'en'">indoor exhibition area</span>
            </div>
          </div>
          <!-- 单一内容结束 -->
          <!-- 单一内容开始 -->
          <div class="middle2-item-sm">
            <div class="middle2-item-msg1-sm" v-show="language == 'cn'">
              <span class="number">4</span>
              <span class="unit">万平方米</span>
            </div>
            <div class="middle2-item-msg1-sm" v-show="language == 'en'">
              <span class="number">40</span>
              <span class="unit">K㎡</span>
            </div>
            <div class="middle2-item-msg2-sm">
              <span v-show="language == 'cn'">室外广场展览面积</span>
              <span v-show="language == 'en'"
                >outdoor plaza exhibition area</span
              >
            </div>
          </div>
          <!-- 单一内容结束 -->
          <!-- 单一内容开始 -->
          <div class="middle2-item-sm">
            <div class="middle2-item-msg1-sm">
              <span class="number">6880</span>
              <span class="unit" v-show="language == 'cn'">个</span>
            </div>
            <div class="middle2-item-msg2-sm">
              <span v-show="language == 'cn'">最多可提供标准展位</span>
              <span v-show="language == 'en'">can be provided</span>
            </div>
          </div>
          <!-- 单一内容结束 -->
        </div>
      </div>
      <!-- 内容容器结束 -->
    </div>
    <!-- 武汉博览中心结束 -->
    <!-- 内容切换开始 -->
    <div class="middle3 hidden-xs-only">
      <div class="container">
        <div class="middle3-content">
          <!-- tab 开始 -->
          <div class="middle3-tab">
            <div
              class="middle3-tab-item"
              :class="tab == 0 ? 'middle3-tab-actived' : ''"
              @click="tab = 0"
            >
              {{ $t("message.subNav2_1") }}
            </div>
            <div
              class="middle3-tab-item"
              :class="tab == 1 ? 'middle3-tab-actived' : ''"
              @click="tab = 1"
            >
              {{ $t("message.subNav2_2") }}
            </div>
            <div
              class="middle3-tab-item"
              :class="tab == 2 ? 'middle3-tab-actived' : ''"
              @click="tab = 2"
            >
              {{ $t("message.subNav2_3") }}
            </div>
            <div
              class="middle3-tab-item"
              :class="tab == 3 ? 'middle3-tab-actived' : ''"
              @click="tab = 3"
            >
              {{ $t("message.subNav2_4") }}
            </div>
            <div
              class="middle3-tab-item"
              :class="tab == 4 ? 'middle3-tab-actived' : ''"
              @click="tab = 4"
            >
              {{ $t("message.subNav2_5") }}
            </div>
          </div>
          <!-- tab 结束 -->
        </div>
      </div>
    </div>
    <div class="middle-hr hidden-sm-and-up"></div>
    <div class="middle3 hidden-sm-and-up">
      <div class="container">
        <el-row :gutter="10">
          <el-col :span="8">
            <div
              class="middle3-tab-item"
              :class="tab == 0 ? 'middle3-tab-actived' : ''"
              @click="tab = 0"
            >
              {{ $t("message.subNav2_1") }}
            </div>
          </el-col>
          <el-col :span="8">
            <div
              class="middle3-tab-item"
              :class="tab == 1 ? 'middle3-tab-actived' : ''"
              @click="tab = 1"
            >
              {{ $t("message.subNav2_2") }}
            </div>
          </el-col>
          <el-col :span="8">
            <div
              class="middle3-tab-item"
              :class="tab == 2 ? 'middle3-tab-actived' : ''"
              @click="tab = 2"
            >
              {{ $t("message.subNav2_3") }}
            </div>
          </el-col>
          <el-col :span="8">
            <div
              class="middle3-tab-item"
              :class="tab == 3 ? 'middle3-tab-actived' : ''"
              @click="tab = 3"
            >
              {{ $t("message.subNav2_4") }}
            </div>
          </el-col>
          <el-col :span="8">
            <div
              class="middle3-tab-item"
              :class="tab == 4 ? 'middle3-tab-actived' : ''"
              @click="tab = 4"
            >
              {{ $t("message.subNav2_5") }}
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 内容切换结束 -->
    <!-- 展馆内容开始 -->
    <div class="exhibition hidden-xs-only" v-show="tab == 0">
      <!-- 内容容器开始 -->
      <div class="container">
        <!-- 内容1开始 -->
        <div
          class="exhibition-content1"
          v-if="exhibitionList && exhibitionList.length != 0"
        >
          <div class="exhibition-content1-img">
            <img :src="exhibitionList.img" alt="" />
          </div>
          <div class="exhibition-content1-msg">
            <div class="pt-30" v-show="language == 'cn'">
              {{ exhibitionList.content }}
            </div>
            <div v-show="language == 'en'">{{ exhibitionList.enContent }}</div>
          </div>
        </div>
        <!-- 内容1结束 -->
        <!-- 内容2开始 -->
        <div class="exhibition-content2">
          <div class="exhibition-content2-img">
            <img src="../assets/img/exhibition-img1.png" alt="" />
          </div>
          <div class="exhibition-content2-right">
            <div class="exhibition-content2-msg">
              <div class="exhibition-content2-right-img">
                <img src="../assets/svg/left-arrow-icon.svg" alt="" />
              </div>
              <div v-show="language == 'cn'">展厅示意平面图</div>
              <div v-show="language == 'en'">
                Schematic of the exhibition hall
              </div>
            </div>
          </div>
        </div>
        <!-- 内容2结束 -->
        <!-- 内容3开始 -->
        <div class="exhibition-content3">
          <!-- 单一内容开始 -->
          <div class="exhibition-content3-item">
            <div class="exhibition-content3-item-img">
              <img src="../assets/img/exhibition-img2.png" alt="" />
            </div>
            <div class="exhibition-content3-item-title">
              {{ $t("message.zgTitle1") }}
            </div>
          </div>
          <!-- 单一内容结束 -->
          <!-- 单一内容开始 -->
          <div class="exhibition-content3-item">
            <div class="exhibition-content3-item-img">
              <img src="../assets/img/exhibition-img3.png" alt="" />
            </div>
            <div class="exhibition-content3-item-title">
              {{ $t("message.zgTitle2") }}
            </div>
          </div>
          <!-- 单一内容结束 -->
          <!-- 单一内容开始 -->
          <div class="exhibition-content3-item">
            <div class="exhibition-content3-item-img">
              <img src="../assets/img/exhibition-img4.png" alt="" />
            </div>
            <div class="exhibition-content3-item-title">
              {{ $t("message.zgTitle2") }}
            </div>
          </div>
          <!-- 单一内容结束 -->
        </div>
        <!-- 内容3结束 -->
      </div>
      <!-- 内容容器结束 -->
    </div>
    <div class="exhibition hidden-sm-and-up" v-show="tab == 0">
      <!-- 内容容器开始 -->
      <div class="container">
        <!-- 内容1开始 -->
        <div
          class="exhibition-content1-sm"
          v-if="exhibitionList && exhibitionList.length != 0"
        >
          <div class="exhibition-content1-img-sm">
            <img :src="exhibitionList.img" alt="" />
          </div>
          <div class="exhibition-content1-msg-sm">
            <div v-show="language == 'cn'">{{ exhibitionList.content }}</div>
            <div v-show="language == 'en'">
              {{ exhibitionList.enContent }}
            </div>
          </div>
        </div>
        <!-- 内容1结束 -->
        <!-- 内容2开始 -->
        <div class="exhibition-content2-sm">
          <div class="exhibition-content2-img-sm">
            <img src="../assets/img/exhibition-img1.png" alt="" />
          </div>
          <div class="exhibition-content2-bottom-sm">
            <div class="exhibition-content2-bottom-img-sm">
              <img src="../assets/svg/up-arrow-icon.svg" alt="" />
            </div>
            <div v-show="language == 'cn'">展厅示意平面图</div>
            <div v-show="language == 'en'">
              Schematic of the exhibition hall
            </div>
          </div>
        </div>
        <!-- 内容2结束 -->
        <!-- 内容3开始 -->
        <div class="exhibition-content3-sm">
          <el-row :gutter="20">
            <el-col :span="12">
              <!-- 单一内容开始 -->
              <div class="exhibition-content3-item-sm">
                <div class="exhibition-content3-item-img-sm">
                  <img src="../assets/img/exhibition-img2.png" alt="" />
                </div>
                <div class="exhibition-content3-item-title-sm">
                  {{ $t("message.zgTitle1") }}
                </div>
              </div>
              <!-- 单一内容结束 -->
            </el-col>
            <el-col :span="12">
              <!-- 单一内容开始 -->
              <div class="exhibition-content3-item-sm">
                <div class="exhibition-content3-item-img-sm">
                  <img src="../assets/img/exhibition-img3.png" alt="" />
                </div>
                <div class="exhibition-content3-item-title-sm">
                  {{ $t("message.zgTitle2") }}
                </div>
              </div>
              <!-- 单一内容结束 -->
            </el-col>
            <el-col :span="24">
              <!-- 单一内容开始 -->
              <div class="exhibition-content3-item-sm">
                <div class="exhibition-content3-item-img-sm">
                  <img src="../assets/img/exhibition-img4.png" alt="" />
                </div>
                <div class="exhibition-content3-item-title-sm">
                  {{ $t("message.zgTitle2") }}
                </div>
              </div>
              <!-- 单一内容结束 -->
            </el-col>
          </el-row>
        </div>
        <!-- 内容3结束 -->
      </div>
      <!-- 内容容器结束 -->
    </div>

    <!-- 展馆内容结束 -->
    <!-- 会议中心开始 -->
    <div class="meeting" v-show="tab == 1">
      <!-- 会议中心开始 -->
      <div class="meeting1">
        <!-- 内容容器开始 -->
        <div class="container" v-if="meetingList && meetingList.length != 0">
          <div class="hidden-xs-only">
            <!-- 标题开始 -->
            <div class="sub-title1">
              <span></span>
              <div v-show="language == 'cn'">{{ meetingList.title }}</div>
              <div v-show="language == 'en'">{{ meetingList.enTitle }}</div>
            </div>
            <!-- 标题结束 -->
            <!-- 文字开始 -->
            <div class="meeting1-content-msg">
              <div v-show="language == 'cn'">{{ meetingList.content }}</div>
              <div v-show="language == 'en'">{{ meetingList.enContent }}</div>
            </div>
            <!-- 文字结束 -->
            <!-- 图片开始 -->
            <div class="meeting1-content-img">
              <img :src="meetingList.img" alt="" />
            </div>
            <!-- 图片结束 -->
            <!-- 数据开始 -->
            <div class="meeting1-content">
              <!-- 单一内容开始 -->
              <div class="meeting1-item pl-0">
                <!-- 标题开始 -->
                <div class="meeting1-item-title">
                  <label v-show="language == 'cn'">阶梯演播厅</label>
                  <label v-show="language == 'en'">Staircase Studio</label>
                </div>
                <!-- 标题结束 -->
                <!-- 线开始 -->
                <div class="meeting1-item-hr"></div>
                <!-- 线结束 -->
                <!-- 数量开始 -->
                <div class="meeting1-item-number">
                  1
                  <label v-show="language == 'cn'">个</label>
                </div>
                <!-- 数量结束 -->
                <!-- 面积开始 -->
                <div class="meeting1-item-area">
                  <span>800</span>
                  <label v-show="language == 'cn'">平方米</label>
                  <label v-show="language == 'en'">㎡</label>
                </div>
                <!-- 面积结束 -->
              </div>
              <!-- 单一内容结束 -->
              <!-- 单一内容开始 -->
              <div class="meeting1-item">
                <!-- 标题开始 -->
                <div class="meeting1-item-title">
                  <label v-show="language == 'cn'">超大宴会厅</label>
                  <label v-show="language == 'en'">Large hall</label>
                </div>
                <!-- 标题结束 -->
                <!-- 线开始 -->
                <div class="meeting1-item-hr"></div>
                <!-- 线结束 -->
                <!-- 数量开始 -->
                <div class="meeting1-item-number">
                  1
                  <label v-show="language == 'cn'">个</label>
                </div>
                <!-- 数量结束 -->
                <!-- 面积开始 -->
                <div class="meeting1-item-area">
                  <span>6000</span>
                  <label v-show="language == 'cn'">平方米</label>
                  <label v-show="language == 'en'">㎡</label>
                </div>
                <!-- 面积结束 -->
              </div>
              <!-- 单一内容结束 -->
              <!-- 单一内容开始 -->
              <div class="meeting1-item">
                <!-- 标题开始 -->
                <div class="meeting1-item-title">
                  <label v-show="language == 'cn'">高级多功能厅</label>
                  <label v-show="language == 'en'">Senior hall</label>
                </div>
                <!-- 标题结束 -->
                <!-- 线开始 -->
                <div class="meeting1-item-hr"></div>
                <!-- 线结束 -->
                <!-- 数量开始 -->
                <div class="meeting1-item-number">
                  2
                  <label v-show="language == 'cn'">个</label>
                </div>
                <!-- 数量结束 -->
                <!-- 面积开始 -->
                <div class="meeting1-item-area">
                  <span>800</span>
                  <label v-show="language == 'cn'">平方米</label>
                  <label v-show="language == 'en'">㎡</label>
                </div>
                <!-- 面积结束 -->
              </div>
              <!-- 单一内容结束 -->
              <!-- 单一内容开始 -->
              <div class="meeting1-item">
                <!-- 标题开始 -->
                <div class="meeting1-item-title">
                  <label v-show="language == 'cn'">标准多功能厅</label>
                  <label v-show="language == 'en'">Standard hall</label>
                </div>
                <!-- 标题结束 -->
                <!-- 线开始 -->
                <div class="meeting1-item-hr"></div>
                <!-- 线结束 -->
                <!-- 数量开始 -->
                <div class="meeting1-item-number">
                  29
                  <label v-show="language == 'cn'">个</label>
                </div>
                <!-- 数量结束 -->
                <!-- 面积开始 -->
                <div class="meeting1-item-area">
                  <span>400</span>
                  <label v-show="language == 'cn'">平方米</label>
                  <label v-show="language == 'en'">㎡</label>
                </div>
                <!-- 面积结束 -->
              </div>
              <!-- 单一内容结束 -->
              <!-- 单一内容开始 -->
              <div class="meeting1-item">
                <!-- 标题开始 -->
                <div class="meeting1-item-title">
                  <label v-show="language == 'cn'">多功能厅和接待厅</label>
                  <label v-show="language == 'en'">reception hall</label>
                </div>
                <!-- 标题结束 -->
                <!-- 线开始 -->
                <div class="meeting1-item-hr"></div>
                <!-- 线结束 -->
                <!-- 数量开始 -->
                <div class="meeting1-item-number">
                  <div v-show="language == 'cn'">不等</div>
                  <div v-show="language == 'en'">area</div>
                </div>
                <!-- 数量结束 -->
                <!-- 面积开始 -->
                <div class="meeting1-item-area">
                  <span>85-300</span>
                  <label v-show="language == 'cn'">平方米</label>
                  <label v-show="language == 'en'">㎡</label>
                </div>
                <!-- 面积结束 -->
              </div>
              <!-- 单一内容结束 -->
            </div>
            <!-- 数据结束 -->
          </div>
          <div class="hidden-sm-and-up">
            <!-- 内容1开始 -->
            <div class="meeting1-sm-content">
              <!-- 文字开始 -->
              <div class="meeting1-content-msg-sm">
                <div v-show="language == 'cn'">{{ meetingList.content }}</div>
                <div v-show="language == 'en'">{{ meetingList.enContent }}</div>
              </div>
              <!-- 文字结束 -->
              <!-- 图片开始 -->
              <div class="meeting1-content-img-sm">
                <img :src="meetingList.img" alt="" />
              </div>
              <!-- 图片结束 -->
            </div>
            <!-- 内容1结束 -->
            <!-- 内容2开始 -->
            <div>
              <el-row :gutter="10">
                <el-col :span="8">
                  <div class="meeting1-item-sm">
                    <!-- 标题开始 -->
                    <div class="meeting1-item-title-sm">
                      <label v-show="language == 'cn'">阶梯演播厅</label>
                      <label v-show="language == 'en'">Staircase Studio</label>
                    </div>
                    <!-- 标题结束 -->
                    <!-- 线开始 -->
                    <div class="meeting1-item-hr-sm"></div>
                    <!-- 线结束 -->
                    <!-- 数量开始 -->
                    <div class="meeting1-item-number-sm">
                      1
                      <label v-show="language == 'cn'">个</label>
                    </div>
                    <!-- 数量结束 -->
                    <!-- 面积开始 -->
                    <div class="meeting1-item-area-sm">
                      <span>800</span>
                      <label v-show="language == 'cn'">平方米</label>
                      <label v-show="language == 'en'">㎡</label>
                    </div>
                    <!-- 面积结束 -->
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="meeting1-item-sm">
                    <!-- 标题开始 -->
                    <div class="meeting1-item-title-sm">
                      <label v-show="language == 'cn'">超大宴会厅</label>
                      <label v-show="language == 'en'">Large hall</label>
                    </div>
                    <!-- 标题结束 -->
                    <!-- 线开始 -->
                    <div class="meeting1-item-hr-sm"></div>
                    <!-- 线结束 -->
                    <!-- 数量开始 -->
                    <div class="meeting1-item-number-sm">
                      1
                      <label v-show="language == 'cn'">个</label>
                    </div>
                    <!-- 数量结束 -->
                    <!-- 面积开始 -->
                    <div class="meeting1-item-area-sm">
                      <span>6000</span>
                      <label v-show="language == 'cn'">平方米</label>
                      <label v-show="language == 'en'">㎡</label>
                    </div>
                    <!-- 面积结束 -->
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="meeting1-item-sm">
                    <!-- 标题开始 -->
                    <div class="meeting1-item-title-sm">
                      <label v-show="language == 'cn'">高级多功能厅</label>
                      <label v-show="language == 'en'">Senior hall</label>
                    </div>
                    <!-- 标题结束 -->
                    <!-- 线开始 -->
                    <div class="meeting1-item-hr-sm"></div>
                    <!-- 线结束 -->
                    <!-- 数量开始 -->
                    <div class="meeting1-item-number-sm">
                      2
                      <label v-show="language == 'cn'">个</label>
                    </div>
                    <!-- 数量结束 -->
                    <!-- 面积开始 -->
                    <div class="meeting1-item-area-sm">
                      <span>800</span>
                      <label v-show="language == 'cn'">平方米</label>
                      <label v-show="language == 'en'">㎡</label>
                    </div>
                    <!-- 面积结束 -->
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="meeting1-item-sm">
                    <!-- 标题开始 -->
                    <div class="meeting1-item-title-sm">
                      <label v-show="language == 'cn'">标准多功能厅</label>
                      <label v-show="language == 'en'">Standard hall</label>
                    </div>
                    <!-- 标题结束 -->
                    <!-- 线开始 -->
                    <div class="meeting1-item-hr-sm"></div>
                    <!-- 线结束 -->
                    <!-- 数量开始 -->
                    <div class="meeting1-item-number-sm">
                      29
                      <label v-show="language == 'cn'">个</label>
                    </div>
                    <!-- 数量结束 -->
                    <!-- 面积开始 -->
                    <div class="meeting1-item-area-sm">
                      <span>400</span>
                      <label v-show="language == 'cn'">平方米</label>
                      <label v-show="language == 'en'">㎡</label>
                    </div>
                    <!-- 面积结束 -->
                  </div>
                </el-col>
                <el-col :span="16">
                  <div class="meeting1-item-sm">
                    <!-- 标题开始 -->
                    <div class="meeting1-item-title-sm">
                      <label v-show="language == 'cn'">多功能厅和接待厅</label>
                      <label v-show="language == 'en'">reception hall</label>
                    </div>
                    <!-- 标题结束 -->
                    <!-- 线开始 -->
                    <div class="meeting1-item-hr-sm"></div>
                    <!-- 线结束 -->
                    <!-- 数量开始 -->
                    <div class="meeting1-item-number-sm">
                      <div v-show="language == 'cn'">不等</div>
                      <div v-show="language == 'en'">area</div>
                    </div>
                    <!-- 数量结束 -->
                    <!-- 面积开始 -->
                    <div class="meeting1-item-area-sm">
                      <span>85-300</span>
                      <label v-show="language == 'cn'">平方米</label>
                      <label v-show="language == 'en'">㎡</label>
                    </div>
                    <!-- 面积结束 -->
                  </div>
                </el-col>
              </el-row>
            </div>
            <!-- 内容2结束 -->
          </div>
        </div>
        <!-- 内容容器结束 -->
      </div>
      <!-- 会议中心结束 -->
      <!-- 宴会厅开始 -->
      <div
        class="meeting2 hidden-xs-only"
        v-if="banquetList && banquetList.length != 0"
      >
        <!-- 内容容器开始 -->
        <div class="container">
          <!-- 标题开始 -->
          <div class="sub-title1">
            <span></span>
            <div v-show="language == 'cn'">大宴会厅</div>
            <div v-show="language == 'en'">Grand banquet hall</div>
          </div>
          <!-- 标题结束 -->
          <div class="meeting2-content">
            <!-- 内容1开始 -->
            <div class="meeting2-content1">
              <!-- 图片开始 -->
              <div class="meeting2-content1-img">
                <img :src="banquetList[0].img" alt="" />
              </div>
              <!-- 图片结束 -->
              <!-- 右侧内容开始 -->
              <div class="meeting2-content1-right">
                <div class="meeting2-content1-right-content">
                  <div class="meeting2-content1-right-txt">
                    <div v-show="language == 'cn'">
                      {{ banquetList[0].content }}
                    </div>
                    <div v-show="language == 'en'">
                      {{ banquetList[0].enContent }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- 右侧内容结束 -->
            </div>
            <!-- 内容1结束 -->
            <!-- 内容2开始 -->
            <div class="meeting2-content2">
              <!-- 左侧内容开始 -->
              <div class="meeting2-content2-left">
                <div class="flex">
                  <div class="meeting2-content2-left-img">
                    <img src="../assets/img/meeting-icon-img1.png" alt="" />
                  </div>
                  <div class="meeting2-content1-left-content">
                    <div class="meeting2-content1-left-msg">
                      <div v-show="language == 'cn'">
                        {{ banquetList[1].content }}
                      </div>
                      <div v-show="language == 'en'">
                        {{ banquetList[1].enContent }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 左侧内容结束 -->
              <!-- 图片开始 -->
              <div class="meeting2-content2-img">
                <img :src="banquetList[1].img" alt="" />
              </div>
              <!-- 图片结束 -->
            </div>
            <!-- 内容2结束 -->
            <!-- 内容3开始 -->
            <div class="meeting2-content3">
              <!-- 图片开始 -->
              <div class="meeting2-content3-img">
                <img :src="banquetList[2].img" alt="" />
              </div>
              <!-- 图片结束 -->
              <!-- 右侧内容开始 -->
              <div class="meeting2-content3-right">
                <div v-show="language == 'cn'">
                  {{ banquetList[2].content }}
                </div>
                <div v-show="language == 'en'">
                  {{ banquetList[2].enContent }}
                </div>
              </div>
              <!-- 右侧内容结束 -->
            </div>
            <!-- 内容3结束 -->
          </div>
        </div>
        <!-- 内容容器结束 -->
      </div>
      <div class="middle-hr hidden-sm-and-up"></div>
      <div
        class="meeting2-sm hidden-sm-and-up"
        v-if="banquetList && banquetList.length != 0"
      >
        <!-- 内容容器开始 -->
        <div class="container">
          <div class="meeting2-content">
            <!-- 标题开始 -->
            <div class="sub-title1">
              <span></span>
              <div v-show="language == 'cn'">大宴会厅</div>
              <div v-show="language == 'en'">Grand banquet hall</div>
            </div>
            <!-- 标题结束 -->
            <!-- 图片开始 -->
            <div class="meeting2-img-sm">
              <img :src="banquetList[0].img" alt="" />
            </div>
            <!-- 图片结束 -->
            <!-- 文字开始 -->
            <div class="meeting2-msg-sm">
              <div v-show="language == 'cn'">
                {{ banquetList[0].content }}
              </div>
              <div v-show="language == 'en'">
                {{ banquetList[0].enContent }}
              </div>
            </div>
            <!-- 文字结束 -->
            <!-- 图片开始 -->
            <div class="meeting2-img-sm">
              <img :src="banquetList[1].img" alt="" />
            </div>
            <!-- 图片结束 -->
            <!-- 文字开始 -->
            <div class="meeting2-msg-sm">
              <div v-show="language == 'cn'">
                {{ banquetList[1].content }}
              </div>
              <div v-show="language == 'en'">
                {{ banquetList[1].enContent }}
              </div>
            </div>
            <!-- 文字结束 -->
            <!-- 图片开始 -->
            <div class="meeting2-img-sm">
              <img :src="banquetList[2].img" alt="" />
            </div>
            <!-- 图片结束 -->
            <!-- 文字开始 -->
            <div class="meeting2-msg-sm">
              <div v-show="language == 'cn'">
                {{ banquetList[2].content }}
              </div>
              <div v-show="language == 'en'">
                {{ banquetList[2].enContent }}
              </div>
            </div>
            <!-- 文字结束 -->
          </div>
        </div>
        <!-- 内容容器结束 -->
      </div>
      <!-- 宴会厅结束 -->
    </div>
    <!-- 会议中心结束 -->
    <!-- 洲际酒店内容开始 -->
    <div v-show="tab == 2">
      <!-- 武汉洲际酒店开始 -->
      <div class="middle5" v-if="continentList && continentList.length != 0">
        <!-- 内容容器开始 -->
        <div class="container">
          <div class="middle5-content hidden-xs-only">
            <!-- 左侧内容开始 -->
            <div class="middle5-content-left">
              <!-- 标题开始 -->
              <div class="sub-title1">
                <span></span>
                <div v-show="language == 'cn'">{{ continentList.title }}</div>
                <div v-show="language == 'en'">{{ continentList.enTitle }}</div>
              </div>
              <!-- 标题结束 -->
              <!-- 内容开始 -->
              <div class="middle5-content-msg">
                <div v-show="language == 'cn'">{{ continentList.content }}</div>
                <div v-show="language == 'en'">
                  {{ continentList.enContent }}
                </div>
              </div>
              <!-- 内容结束 -->
            </div>
            <!-- 左侧内容开始 -->
            <!-- 右侧内容开始 -->
            <div class="middle5-content-right">
              <img :src="continentList.img" alt="" />
            </div>
            <!-- 右侧内容开始 -->
          </div>
          <div class="middle5-content-sm hidden-sm-and-up">
            <!-- 文字开始 -->
            <div class="middle5-content-msg-sm">
              <div v-show="language == 'cn'">{{ continentList.content }}</div>
              <div v-show="language == 'en'">
                {{ continentList.enContent }}
              </div>
            </div>
            <!-- 文字结束 -->
            <!-- 图片开始 -->
            <div class="middle5-content-img-sm">
              <img :src="continentList.img" alt="" />
            </div>
            <!-- 图片结束 -->
          </div>
        </div>
        <!-- 内容容器结束 -->
      </div>
      <!-- 武汉洲际酒店结束 -->
      <!-- 客房开始 -->
      <div class="middle6 hidden-xs-only">
        <!-- 内容容器开始 -->
        <div class="container">
          <!-- 标题开始 -->
          <div class="sub-title1">
            <span></span>
            <div v-show="language == 'cn'">豪华客房（485间）</div>
            <div v-show="language == 'en'">Deluxe guest rooms (485 rooms)</div>
          </div>
          <!-- 标题结束 -->
          <!-- 内容开始 -->
          <div class="middle6-content">
            <!-- 左侧内容开始 -->
            <div class="middle6-content-left">
              <img src="../assets/img/about-img5.png" alt="" />
            </div>
            <!-- 左侧内容结束 -->
            <!-- 右侧内容开始 -->
            <div class="middle6-content-right">
              <!-- 提示开始 -->
              <div class="middle6-content-tips">
                <div class="hr-1"><div class="hr-2"></div></div>
                <div>尽阅长江波澜，引领智者风范</div>
              </div>
              <!-- 提示结束 -->
              <!-- 表格开始 -->
              <div class="middle6-content-table">
                <div class="middle6-table-item middle6-table-header">
                  <div class="middle6-table-item-type">
                    <div class="middle6-table-enName">Guest Room Type</div>
                    <div class="middle6-table-name">酒店客房房型</div>
                  </div>
                  <div class="middle6-table-item-number">
                    <div class="middle6-table-enNo">No</div>
                    <div class="middle6-table-no">数量</div>
                  </div>
                  <div class="middle6-table-item-size">
                    <div class="middle6-table-enSize">Size</div>
                    <div class="middle6-table-size">面积</div>
                  </div>
                </div>
                <div
                  class="middle6-table-item"
                  v-for="(item, index) in tableData"
                  :key="index"
                >
                  <div class="middle6-table-item-type">
                    <div class="middle6-table-enName">{{ item.enName }}</div>
                    <div class="middle6-table-name">{{ item.name }}</div>
                  </div>
                  <div class="middle6-table-item-number">
                    <div class="middle6-table-no">{{ item.no }}</div>
                  </div>
                  <div class="middle6-table-item-size">
                    <div class="middle6-table-size">{{ item.size }}</div>
                  </div>
                </div>
              </div>
              <!-- 表格结束 -->
            </div>
            <!-- 右侧内容结束 -->
          </div>
          <!-- 内容结束 -->
        </div>
        <!-- 内容容器结束 -->
      </div>
      <div class="middle6-sm hidden-sm-and-up">
        <!-- 内容容器开始 -->
        <div class="container">
          <!-- 标题开始 -->
          <div class="sub-title1">
            <span></span>
            <div v-show="language == 'cn'">豪华客房（485间）</div>
            <div v-show="language == 'en'">Deluxe guest rooms (485 rooms)</div>
          </div>
          <!-- 标题结束 -->
          <!-- 内容开始 -->
          <div class="middle6-content-sm">
            <!-- 左侧内容开始 -->
            <div class="middle6-content-left-sm">
              <img src="../assets/img/about-img5.png" alt="" />
            </div>
            <!-- 左侧内容结束 -->
          </div>
          <!-- 内容结束 -->
          <!-- 表格开始 -->
          <!-- 表格开始 -->
          <div class="middle6-content-table">
            <div class="middle6-table-item middle6-table-header">
              <div class="middle6-table-item-type">
                <div class="middle6-table-enName">Guest Room Type</div>
                <div class="middle6-table-name">酒店客房房型</div>
              </div>
              <div class="middle6-table-item-number">
                <div class="middle6-table-enNo">No</div>
                <div class="middle6-table-no">数量</div>
              </div>
              <div class="middle6-table-item-size">
                <div class="middle6-table-enSize">Size</div>
                <div class="middle6-table-size">面积</div>
              </div>
            </div>
            <div
              class="middle6-table-item"
              v-for="(item, index) in tableData"
              :key="index"
            >
              <div class="middle6-table-item-type">
                <div class="middle6-table-enName">{{ item.enName }}</div>
                <div class="middle6-table-name">{{ item.name }}</div>
              </div>
              <div class="middle6-table-item-number">
                <div class="middle6-table-no">{{ item.no }}</div>
              </div>
              <div class="middle6-table-item-size">
                <div class="middle6-table-size">{{ item.size }}</div>
              </div>
            </div>
          </div>
          <!-- 表格结束 -->
          <!-- 表格结束 -->
        </div>
        <!-- 内容容器结束 -->
      </div>
      <!-- 客房结束 -->
      <!-- 餐饮开始 -->
      <div
        class="middle7 hidden-xs-only"
        v-if="restaurantList1 && restaurantList1.length != 0"
      >
        <!-- 内容容器开始 -->
        <div class="container">
          <!-- 标题开始 -->
          <div class="sub-title1">
            <span></span>
            <div v-show="language == 'cn'">餐饮服务设施</div>
            <div v-show="language == 'en'">Food service facilities</div>
          </div>
          <!-- 标题结束 -->
          <!-- 内容开始 -->
          <div class="middle7-content">
            <!-- 左侧内容开始 -->
            <div class="middle7-content-img">
              <img :src="restaurantList1.img" alt="" />
            </div>
            <!-- 左侧内容结束 -->
            <!-- 右侧内容开始 -->
            <div class="middle7-content-msgs1">
              <div>
                <!-- 标题开始 -->
                <div class="middle7-msgs-title">
                  <span></span>
                  <div class="middle7-msgs-title-text">
                    <div v-show="language == 'cn'">
                      {{ restaurantList1.title }}
                    </div>
                    <div v-show="language == 'en'">
                      {{ restaurantList1.enTitle }}
                    </div>
                  </div>
                </div>
                <!-- 标题结束 -->
                <!-- 信息开始 -->
                <div class="middle7-content-msgs1-text">
                  <div v-show="language == 'cn'">
                    {{ restaurantList1.content }}
                  </div>
                  <div v-show="language == 'en'">
                    {{ restaurantList1.enContent }}
                  </div>
                  <div class="msg">
                    {{ $t("message.eat") }}：
                    <div class="middle7-msgs1-text-blue">
                      <span v-show="language == 'cn'">
                        {{ restaurantList1.number }}
                      </span>
                      <span v-show="language == 'en'">
                        {{ restaurantList1.enNumber }}
                      </span>
                      人
                    </div>
                  </div>
                </div>
                <!-- 信息结束 -->
              </div>
              <div class="flex justify-end">
                <img src="../assets/img/about-img7.png" alt="" />
              </div>
            </div>
            <!-- 右侧内容结束 -->
          </div>
          <!-- 内容结束 -->
          <!-- 内容开始 -->
          <div class="middle7-content">
            <!-- 左侧内容开始 -->
            <div
              class="middle7-content-msgs2"
              v-if="restaurantList2 && restaurantList2.length != 0"
            >
              <div>
                <!-- 标题开始 -->
                <div class="middle7-msgs-title">
                  <span></span>
                  <div class="middle7-msgs-title-text">
                    <div v-show="language == 'cn'">
                      {{ restaurantList2.title }}
                    </div>
                    <div v-show="language == 'en'">
                      {{ restaurantList2.enTitle }}
                    </div>
                  </div>
                </div>
                <!-- 标题结束 -->
                <!-- 信息开始 -->
                <div class="middle7-content-msgs2-text">
                  <div v-show="language == 'cn'">
                    {{ restaurantList2.content }}
                  </div>
                  <div v-show="language == 'en'">
                    {{ restaurantList2.enContent }}
                  </div>
                  <div class="msg">
                    {{ $t("message.eat") }}：
                    <div class="middle7-msgs2-text-blue">
                      <span v-show="language == 'cn'">
                        {{ restaurantList2.number }}
                      </span>
                      <span v-show="language == 'en'">
                        {{ restaurantList2.enNumber }}
                      </span>
                      人
                    </div>
                  </div>
                </div>
                <!-- 信息结束 -->
              </div>
            </div>
            <!-- 左侧内容结束 -->
            <!-- 右侧内容开始 -->
            <div class="middle7-content-img">
              <img :src="restaurantList2.img" alt="" />
            </div>
            <!-- 右侧内容结束 -->
          </div>
          <!-- 内容结束 -->
        </div>
        <!-- 内容容器结束 -->
      </div>
      <div class="middle-hr hidden-sm-and-up"></div>
      <div
        class="middle7-sm hidden-sm-and-up"
        v-if="restaurantList1 && restaurantList1.length != 0"
      >
        <!-- 内容容器开始 -->
        <div class="container">
          <!-- 标题开始 -->
          <div class="sub-title1">
            <span></span>
            <div v-show="language == 'cn'">餐饮服务设施</div>
            <div v-show="language == 'en'">Food service facilities</div>
          </div>
          <!-- 标题结束 -->
          <!-- 内容开始 -->
          <div class="middle7-content-sm">
            <!-- 图片开始 -->
            <div class="middle7-content-img-sm">
              <img :src="restaurantList1.img" alt="" />
            </div>
            <!-- 图片结束 -->
            <!-- 文字开始 -->
            <div class="middle7-content-box-sm">
              <!-- 标题开始 -->
              <div class="middle7-msgs-title-sm">
                <span></span>
                <div class="middle7-msgs-title-text-sm">
                  <div v-show="language == 'cn'">
                    {{ restaurantList1.title }}
                  </div>
                  <div v-show="language == 'en'">
                    {{ restaurantList1.enTitle }}
                  </div>
                </div>
              </div>
              <!-- 标题结束 -->
              <!-- 信息开始 -->
              <div class="middle7-content-msgs1-text-sm">
                <div v-show="language == 'cn'">
                  {{ restaurantList1.content }}
                </div>
                <div v-show="language == 'en'">
                  {{ restaurantList1.enContent }}
                </div>
                <div class="msg">
                  {{ $t("message.eat") }}：
                  <div class="middle7-msgs1-text-blue">
                    <span v-show="language == 'cn'">
                      {{ restaurantList1.number }}
                    </span>
                    <span v-show="language == 'en'">
                      {{ restaurantList1.enNumber }}
                    </span>
                    人
                  </div>
                </div>
              </div>
              <!-- 信息结束 -->
            </div>
            <!-- 文字结束 -->
            <!-- 图片开始 -->
            <div class="middle7-content-img-sm">
              <img :src="restaurantList2.img" alt="" />
            </div>
            <!-- 图片结束 -->
            <!-- 文字开始 -->
            <div class="middle7-content-box-sm">
              <!-- 标题开始 -->
              <div class="middle7-msgs-title-sm">
                <span></span>
                <div class="middle7-msgs-title-text-sm">
                  <div v-show="language == 'cn'">
                    {{ restaurantList2.title }}
                  </div>
                  <div v-show="language == 'en'">
                    {{ restaurantList2.enTitle }}
                  </div>
                </div>
              </div>
              <!-- 标题结束 -->
              <!-- 信息开始 -->
              <div class="middle7-content-msgs1-text-sm">
                <div v-show="language == 'cn'">
                  {{ restaurantList2.content }}
                </div>
                <div v-show="language == 'en'">
                  {{ restaurantList2.enContent }}
                </div>
                <div class="msg">
                  {{ $t("message.eat") }}：
                  <div class="middle7-msgs1-text-blue">
                    <span v-show="language == 'cn'">
                      {{ restaurantList2.number }}
                    </span>
                    <span v-show="language == 'en'">
                      {{ restaurantList2.enNumber }}
                    </span>
                    人
                  </div>
                </div>
              </div>
              <!-- 信息结束 -->
            </div>
            <!-- 文字结束 -->
          </div>
          <!-- 内容结束 -->
        </div>
        <!-- 内容容器结束 -->
      </div>
      <!-- 餐饮结束 -->
      <!-- 大堂开始 -->
      <div
        class="middle8 hidden-xs-only"
        v-if="lobbyList && lobbyList.length != 0"
      >
        <!-- 内容开始 -->
        <div class="middle8-content">
          <!-- 图片开始 -->
          <div class="middle8-content-img">
            <img :src="lobbyList.img" alt="" />
          </div>
          <!-- 图片结束 -->
          <div class="middle8-content-msgs">
            <!-- 标题开始 -->
            <div class="sub-title1">
              <span></span>
              <div v-show="language == 'cn'">
                {{ lobbyList.title }}
              </div>
              <div v-show="language == 'en'">
                {{ lobbyList.enTitle }}
              </div>
            </div>
            <!-- 标题结束 -->
            <!-- 信息开始 -->
            <div class="middle8-content-msgs-text">
              <div class="msg1">
                <div v-show="language == 'cn'">
                  {{ lobbyList.content }}
                </div>
                <div v-show="language == 'en'">
                  {{ lobbyList.enContent }}
                </div>
              </div>
              <div class="msg2">
                {{ $t("message.eat") }}：
                <div class="middle8-msgs-text-blue">
                  <span v-show="language == 'cn'">
                    {{ lobbyList.number }}
                  </span>
                  <span v-show="language == 'en'">
                    {{ lobbyList.enNumber }}
                  </span>
                  人
                </div>
              </div>
              <div class="msg3 text-blue">
                {{ $t("message.salesHotline") }}：
              </div>
              <div class="msg4">027-84840000</div>
            </div>
            <!-- 信息结束 -->
          </div>
        </div>
        <!-- 内容结束 -->
      </div>
      <div class="middle-hr hidden-sm-and-up"></div>
      <div
        class="middle8-sm hidden-sm-and-up"
        v-if="lobbyList && lobbyList.length != 0"
      >
        <div class="container">
          <!-- 标题开始 -->
          <div class="sub-title1">
            <span></span>
            <div v-show="language == 'cn'">
              {{ lobbyList.title }}
            </div>
            <div v-show="language == 'en'">
              {{ lobbyList.enTitle }}
            </div>
          </div>
          <!-- 标题结束 -->
          <!-- 图片开始 -->
          <div class="middle8-img-sm">
            <img src="../assets/img/about-img9.png" alt="" />
          </div>
          <!-- 图片结束 -->
          <!-- 信息开始 -->
          <div class="middle8-content-sm">
            <div>
              <div v-show="language == 'cn'">
                {{ lobbyList.content }}
              </div>
              <div v-show="language == 'en'">
                {{ lobbyList.enContent }}
              </div>
            </div>
            <div>
              {{ $t("message.eat") }}：
              <div class="middle8-sm-text-blue">
                <span v-show="language == 'cn'">
                  {{ lobbyList.number }}
                </span>
                <span v-show="language == 'en'">
                  {{ lobbyList.number }}
                </span>
                人
              </div>
            </div>
          </div>
          <!-- 信息结束 -->
          <!-- 电话开始 -->
          <div class="middle8-sm-phone">
            <div>
              <img src="../assets/svg/footer-phone-icon.svg" alt="" srcset="" />
            </div>
            <div class="text-blue">{{ $t("message.salesHotline") }}：</div>
            <div class="msg4">027-84840000</div>
          </div>
          <!-- 电话结束 -->
        </div>
      </div>
      <!-- 大堂结束 -->
    </div>
    <!-- 洲际酒店内容结束 -->
    <!-- 套房假日酒店开始 -->
    <div class="holidayInn" v-show="tab == 3">
      <!-- 第一部分开始 -->
      <div class="holidayInn-middle">
        <div class="container">
          <!-- 内容开始 -->
          <div
            class="holidayInn-middle-content hidden-xs-only"
            v-if="holidayList && holidayList.length != 0"
          >
            <!-- 单一内容开始 -->
            <div class="holidayInn-middle-content-item">
              <!-- 图片开始 -->
              <div class="holidayInn-middle-content-img">
                <img :src="holidayList.img" alt="" />
              </div>
              <!-- 图片结束 -->
              <!-- 内容开始 -->
              <div class="holidayInn-middle-content-txt pl-50">
                <!-- 标题开始 -->
                <div class="sub-title1">
                  <span></span>
                  <div v-show="language == 'cn'">{{ holidayList.title }}</div>
                  <div v-show="language == 'en'">{{ holidayList.enTitle }}</div>
                </div>
                <!-- 标题结束 -->
                <!-- 文字开始 -->
                <div class="holidayInn-middle-content-msg">
                  <div v-show="language == 'cn'">{{ holidayList.content }}</div>
                  <div v-show="language == 'en'">
                    {{ holidayList.enContent }}
                  </div>
                </div>
                <!-- 文字结束 -->
              </div>
              <!-- 内容结束 -->
            </div>
            <!-- 单一内容结束 -->
            <div class="holidayInn-middle-content-item">
              <!-- 内容开始 -->
              <div class="holidayInn-middle-content-txt">
                <!-- 标题开始 -->
                <div class="holidayInn-middle-content-title">
                  <div class="holidayInn-middle-content-title-msg">
                    <div v-show="language == 'cn'">{{ configList.title }}</div>
                    <div v-show="language == 'en'">
                      {{ configList.enTitle }}
                    </div>
                  </div>
                  <div class="middle-item-triangle"></div>
                </div>
                <!-- 标题结束 -->
                <!-- 单一内容开始 -->
                <div v-show="language == 'cn'">
                  <div
                    class="holidayInn-middle-content-txt-msg"
                    v-for="(item, index) in configList.content"
                    :key="index"
                  >
                    <span></span>{{ item }}
                  </div>
                </div>
                <div v-show="language == 'en'">
                  <div
                    class="holidayInn-middle-content-txt-msg"
                    v-for="(item, index) in configList.enContent"
                    :key="index"
                  >
                    <span></span>{{ item }}
                  </div>
                </div>

                <!-- 单一内容结束 -->
              </div>
              <!-- 内容结束 -->
              <!-- 图片开始 -->
              <div class="holidayInn-middle-content-img">
                <img :src="configList.img" alt="" />
              </div>
              <!-- 图片结束 -->
            </div>
          </div>
          <div
            class="holidayInn-middle-content-sm hidden-sm-and-up"
            v-if="holidayList && holidayList.length != 0"
          >
            <!-- 单一内容开始 -->
            <div
              class="holidayInn-middle-box-sm"
              v-if="holidayList && holidayList.length != 0"
            >
              <!-- 文字开始 -->
              <div class="holidayInn-middle-content-msg">
                <div v-show="language == 'cn'">{{ holidayList.content }}</div>
                <div v-show="language == 'en'">
                  {{ holidayList.enContent }}
                </div>
              </div>
              <!-- 文字结束 -->
              <!-- 图片开始 -->
              <div class="holidayInn-middle-content-img-sm">
                <img :src="holidayList.img" alt="" />
              </div>
              <!-- 图片结束 -->
            </div>
            <!-- 单一内容结束 -->
            <!-- 单一内容开始 -->
            <div
              class="holidayInn-middle-box-sm"
              v-if="configList && configList.length != 0"
            >
              <!-- 内容开始 -->
              <div class="holidayInn-middle-content-txt-sm">
                <!-- 标题开始 -->
                <div class="holidayInn-middle-content-title">
                  <div class="holidayInn-middle-content-title-msg">
                    <div v-show="language == 'cn'">{{ configList.title }}</div>
                    <div v-show="language == 'en'">
                      {{ configList.enTitle }}
                    </div>
                  </div>
                  <div class="middle-item-triangle"></div>
                </div>
                <!-- 标题结束 -->
                <!-- 单一内容开始 -->
                <div v-show="language == 'cn'">
                  <div
                    class="holidayInn-middle-content-txt-msg"
                    v-for="(item, index) in configList.content"
                    :key="index"
                  >
                    <span></span>{{ item }}
                  </div>
                </div>
                <div v-show="language == 'en'">
                  <div
                    class="holidayInn-middle-content-txt-msg"
                    v-for="(item, index) in configList.enContent"
                    :key="index"
                  >
                    <span></span>{{ item }}
                  </div>
                </div>
                <!-- 单一内容结束 -->
              </div>
              <!-- 内容结束 -->
              <!-- 图片开始 -->
              <div class="holidayInn-middle-content-img-sm">
                <img :src="configList.img" alt="" />
              </div>
              <!-- 图片结束 -->
            </div>
            <!-- 单一内容结束 -->
          </div>
          <!-- 内容结束 -->
        </div>
      </div>
      <!-- 第一部分结束 -->
      <!-- 第二部分开始 -->
      <div
        class="holidayInn-middle2 hidden-xs-only"
        v-if="restaurantList3 && restaurantList3.length != 0"
      >
        <!-- 图片开始 -->
        <div class="holidayInn-middle2-img">
          <img :src="restaurantList3.img" alt="" />
        </div>
        <!-- 图片结束 -->
        <!-- 内容开始 -->
        <div class="holidayInn-middle2-content">
          <!-- 标题开始 -->
          <div class="sub-title1">
            <span></span>
            <div v-show="language == 'cn'">{{ restaurantList3.title }}</div>
            <div v-show="language == 'en'">{{ restaurantList3.enTitle }}</div>
          </div>
          <!-- 标题结束 -->
          <!-- 文字开始 -->
          <div class="holidayInn-middle2-content-msg">
            <div v-show="language == 'cn'">{{ restaurantList3.content }}</div>
            <div v-show="language == 'en'">{{ restaurantList3.enContent }}</div>
          </div>
          <!-- 文字结束 -->
        </div>
        <!-- 内容结束 -->
      </div>
      <div class="middle-hr hidden-sm-and-up"></div>
      <div
        class="holidayInn-middle2-sm hidden-sm-and-up"
        v-if="restaurantList3 && restaurantList3.length != 0"
      >
        <div class="container">
          <!-- 标题开始 -->
          <div class="sub-title1">
            <span></span>
            <div v-show="language == 'cn'">{{ restaurantList3.title }}</div>
            <div v-show="language == 'en'">{{ restaurantList3.enTitle }}</div>
          </div>
          <!-- 标题结束 -->
          <!-- 图片开始 -->
          <div class="holidayInn-middle2-img-sm">
            <img :src="restaurantList3.img" alt="" />
          </div>
          <!-- 图片结束 -->
          <!-- 文字开始 -->
          <div class="holidayInn-middle2-content-msg-sm">
            <div v-show="language == 'cn'">{{ restaurantList3.content }}</div>
            <div v-show="language == 'en'">{{ restaurantList3.enContent }}</div>
          </div>
          <!-- 文字结束 -->
        </div>
      </div>
      <div class="middle-hr hidden-sm-and-up"></div>
      <!-- 第二部分结束 -->
      <!-- 第三部分开始 -->
      <div class="container">
        <div
          class="flex hidden-xs-only"
          v-if="LoungeList && LoungeList.length != 0"
        >
          <!-- 内容开始 -->
          <div class="holidayInn-middle2-content pr-50">
            <!-- 标题开始 -->
            <div class="sub-title1">
              <span></span>
              <div v-show="language == 'cn'">{{ LoungeList.title }}</div>
              <div v-show="language == 'en'">{{ LoungeList.enTitle }}</div>
            </div>
            <!-- 标题结束 -->
            <!-- 文字开始 -->
            <div class="holidayInn-middle2-content-msg">
              <div v-show="language == 'cn'">{{ LoungeList.content }}</div>
              <div v-show="language == 'en'">{{ LoungeList.enContent }}</div>
            </div>
            <!-- 文字结束 -->
          </div>
          <!-- 内容结束 -->
          <!-- 图片开始 -->
          <div class="holidayInn-middle3-img">
            <img :src="LoungeList.img" alt="" />
          </div>
          <!-- 图片结束 -->
        </div>
        <div
          class="holidayInn-middle2-sm hidden-sm-and-up"
          v-if="LoungeList && LoungeList.length != 0"
        >
          <!-- 标题开始 -->
          <div class="sub-title1">
            <span></span>
            <div v-show="language == 'cn'">{{ LoungeList.title }}</div>
            <div v-show="language == 'en'">{{ LoungeList.enTitle }}</div>
          </div>
          <!-- 标题结束 -->
          <!-- 图片开始 -->
          <div class="holidayInn-middle2-img-sm">
            <img :src="LoungeList.img" alt="" />
          </div>
          <!-- 图片结束 -->
          <!-- 文字开始 -->
          <div class="holidayInn-middle2-content-msg2-sm">
            <div v-show="language == 'cn'">{{ LoungeList.content }}</div>
            <div v-show="language == 'en'">{{ LoungeList.enContent }}</div>
          </div>
          <!-- 文字结束 -->
        </div>
        <!-- 健身房开始 -->
        <div class="gym hidden-xs-only" v-if="gymList && gymList.length != 0">
          <div class="gym-title">
            <!-- 标题开始 -->
            <div class="sub-title1">
              <span></span>
              <div v-show="language == 'cn'">{{ gymList.title }}</div>
              <div v-show="language == 'en'">{{ gymList.enTitle }}</div>
            </div>
            <!-- 标题结束 -->
          </div>
          <!-- 内容开始 -->
          <div class="gym-content">
            <span></span>
            <div v-show="language == 'cn'">{{ gymList.content }}</div>
            <div v-show="language == 'en'">{{ gymList.enContent }}</div>
          </div>
          <!-- 内容结束 -->
        </div>

        <!-- 健身房结束 -->
      </div>
      <!-- 第三部分结束 -->
      <div class="middle-hr hidden-sm-and-up"></div>
      <div
        class="gym-sm hidden-sm-and-up"
        v-if="gymList && gymList.length != 0"
      >
        <div class="gym-title">
          <!-- 标题开始 -->
          <div class="sub-title1">
            <span></span>
            <div v-show="language == 'cn'">{{ gymList.title }}</div>
            <div v-show="language == 'en'">{{ gymList.enTitle }}</div>
          </div>
          <!-- 标题结束 -->
        </div>
        <!-- 内容开始 -->
        <div class="gym-content">
          <span></span>
          <div v-show="language == 'cn'">{{ gymList.content }}</div>
          <div v-show="language == 'en'">{{ gymList.enContent }}</div>
        </div>
        <!-- 内容结束 -->
      </div>
    </div>
    <!-- 套房假日酒店结束 -->
    <!-- 汉厅开始 -->
    <div class="hall" v-show="tab == 4" v-if="hallList && hallList.length != 0">
      <div class="container">
        <!-- 内容开始 -->
        <div class="hall-box hidden-xs-only">
          <div class="flex">
            <!-- 图片开始 -->
            <div class="hall-box-img">
              <img :src="hallList.img" alt="" />
            </div>
            <!-- 图片结束 -->
            <!-- 文字开始 -->
            <div class="hall-box-msg">
              <!-- 标题开始 -->
              <div class="sub-title1">
                <span></span>
                <div v-show="language == 'cn'">{{ hallList.title }}</div>
                <div v-show="language == 'en'">{{ hallList.enTitle }}</div>
              </div>
              <!-- 标题结束 -->
              <div v-show="language == 'cn'">
                {{ hallList.content }}
              </div>
              <div v-show="language == 'en'">
                {{ hallList.enCon1 }}
              </div>
            </div>
            <!-- 文字结束 -->
          </div>
          <div class="hall-box-msg1" v-show="language == 'en'">
            {{ hallList.enCon2 }}
          </div>
        </div>
        <div class="hall-box hidden-sm-and-up">
          <!-- 图片开始 -->
          <div class="hall-box-img">
            <img :src="hallList.img" alt="" />
          </div>
          <!-- 图片结束 -->
          <!-- 文字开始 -->
          <div class="hall-box-msg">
            <!-- 标题开始 -->
            <div class="sub-title1">
              <span></span>
              <div v-show="language == 'cn'">{{ hallList.title }}</div>
              <div v-show="language == 'en'">{{ hallList.enTitle }}</div>
            </div>
            <!-- 标题结束 -->
            <div v-show="language == 'cn'">
              {{ hallList.content }}
            </div>
            <div v-show="language == 'en'">
              {{ hallList.enContent }}
            </div>
          </div>
          <!-- 文字结束 -->
        </div>

        <!-- 内容结束 -->
      </div>
    </div>
    <!-- 汉厅结束 -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      a: [],
      tab: 1, //0、展馆，1、会议中心，2、洲际酒店，3、假日酒店，4、汉厅
      // 表格数据
      tableData: [
        {
          name: "洲际高级房",
          enName: "InterContinental Superior Room",
          no: "186间",
          size: "55㎡",
        },
        {
          name: "洲际江景房",
          enName: "InterContinental River View Room",
          no: "182间",
          size: "55㎡",
        },
        {
          name: "洲际行政豪华房",
          enName: "Club InterContinental Premier Room",
          no: "72间",
          size: "55㎡",
        },
        {
          name: "洲际套房",
          enName: "InterContinental Suite",
          no: "18间",
          size: "115㎡",
        },
        {
          name: "洲际江景套房",
          enName: "InterContinental River Suite",
          no: "25间",
          size: "115㎡",
        },
        {
          name: "总统套房",
          enName: "Presidential Suite",
          no: "1+1间",
          size: "560㎡",
        },
        {
          name: "总房间数",
          enName: "Total",
          no: "485间",
          size: "",
        },
      ],
      titleList: [], //武汉国际博览中心数据
      exhibitionList: [], //展馆数据
      meetingList: [], //会议中心数据
      banquetList: [], //大宴会厅数据
      continentList: [], //武汉洲际酒店数据 悦阁中餐厅
      restaurantList1: [], //悦阁中餐厅
      restaurantList2: [], //漢全日餐厅
      lobbyList: [], //大堂吧
      holidayList: [], //假日酒店
      configList: [], // 所有客房均配备
      restaurantList3: [], //嗨碗餐厅
      LoungeList: [], //大堂酒廊
      gymList: [], //健身房
      hallList: [], //汉厅数据
    };
  },
  mounted() {
    let num = this.$route.query.tab;
    if (num) {
      this.tab = num;
    }
    // 武汉国际博览中心数据
    this.$axios
      .get("api/newList?title=武汉国际博览中心&state=1", {})
      .then((res) => {
        if (res.data.code == 0) {
          let arr = res.data.data[0];
          arr.enCon1 = arr.enContent.slice(0, 949);
          arr.enCon2 = arr.enContent.slice(949);
          this.titleList = arr;
        }
      });
    // 展馆数据
    this.$axios.get("api/newList?title=展馆&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        this.exhibitionList = res.data.data[0];
      }
    });
    // 会议中心数据
    this.$axios.get("api/newList?title=会议中心&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        this.meetingList = res.data.data[0];
      }
    });
    // 大宴会厅数据
    this.$axios.get("api/newList?title=大宴会厅&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        this.banquetList = res.data.data;
      }
    });
    // 武汉洲际酒店数据 restaurantList1
    this.$axios
      .get("api/newList?title=武汉洲际酒店&state=1", {})
      .then((res) => {
        if (res.data.code == 0) {
          this.continentList = res.data.data[0];
        }
      });
    // 悦阁中餐厅数据
    this.$axios.get("api/newList?title=悦阁中餐厅&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        let value = res.data.data[0];
        let arr = value.content.split("&amp;");
        let enArr = value.enContent.split("&amp;");
        value.number = arr[1];
        value.content = arr[0];
        value.enNumber = enArr[1];
        value.enContent = enArr[0];
        this.restaurantList1 = value;
      }
    });
    // 漢全日餐厅数据 lobbyList
    this.$axios.get("api/newList?title=漢全日餐厅&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        let value = res.data.data[0];
        let arr = value.content.split("&amp;");
        let enArr = value.enContent.split("&amp;");
        value.number = arr[1];
        value.content = arr[0];
        value.enNumber = enArr[1];
        value.enContent = enArr[0];
        this.restaurantList2 = value;
      }
    });
    // 大堂吧
    this.$axios.get("api/newList?title=大堂吧&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        let value = res.data.data[0];
        let arr = value.content.split("&amp;");
        let enArr = value.enContent.split("&amp;");
        value.number = arr[1];
        value.content = arr[0];
        value.enNumber = enArr[1];
        value.enContent = enArr[0];
        this.lobbyList = value;
      }
    });

    // 套房假日酒店
    this.$axios
      .get("api/newList?title=套房假日酒店&state=1", {})
      .then((res) => {
        if (res.data.code == 0) {
          this.holidayList = res.data.data[0];
        }
      });
    // 所有客房均配备
    this.$axios
      .get("api/newList?title=所有客房均配备&state=1", {})
      .then((res) => {
        if (res.data.code == 0) {
          let arr = res.data.data[0];
          arr.content = arr.content.split("&amp;");
          arr.enContent = arr.enContent.split("&amp;");
          this.configList = arr;
        }
      });
    // 嗨碗餐厅
    this.$axios.get("api/newList?title=嗨碗餐厅&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        this.restaurantList3 = res.data.data[0];
      }
    });
    // 大堂酒廊
    this.$axios.get("api/newList?title=大堂酒廊&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        this.LoungeList = res.data.data[0];
      }
    });
    // 健身房
    this.$axios.get("api/newList?title=健身房&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        this.gymList = res.data.data[0];
      }
    });
    // 汉厅
    this.$axios.get("api/newList?title=汉厅&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        let arr = res.data.data[0];
        arr.enCon1 = arr.enContent.slice(0, 559);
        arr.enCon2 = arr.enContent.slice(599);
        this.hallList = arr;
      }
    });
  },
  methods: {
    getRowClass({ row, column, rowIndex, columnIndex }) {
      return "background:transparent;";
    },
    // 选择切换图片
    selectBigBanner(value) {
      this.bigbanner = value;
    },
    // 洲际酒店 跳转
    herfHotel() {
      window.open(
        "https://www.ihg.com.cn/intercontinental/hotels/cn/zh/wuhan/wuhha/hoteldetail"
      );
    },
  },
  computed: {
    ...mapState(["language"]),
  },
};
</script>

<style scoped>
.sm-msg {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 26px;
  margin: 20px 0;
}

.sm-img {
  margin: 20px 0;
}

.sm-img img {
  width: 100%;
}

.middle-content {
  margin: 40px 0 60px;
}

.middle-content-left {
  width: 550px;
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
}

.middle-content-left2 {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
}

.middle-content-msg1 {
  margin: 32px 0 65px;
}

.middle-content-right {
  width: 50%;
}

.middle-content-right img {
  width: 100%;
}

.middle2 {
  height: 240px;
  background: url("../assets/img/about-banner2.png") no-repeat center;
}

.middle2-content {
  display: flex;
}

.middle2-item {
  width: 300px;
  height: 240px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.middle2-item-msg1 {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
}

.middle2-item-msg1 .number {
  color: #004bc7;
  font-size: 60px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  margin-right: 10px;
}

.middle2-item-msg1 .unit {
  color: #666666;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 53px;
}

.middle2-item-msg2 {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
}

.middle2-content-sm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.middle2-item-sm {
  width: 48%;
  height: 80px;
  background: #f5f5f5 url("../assets/img/up-left-icon.png") no-repeat -4px -4px;
  margin-bottom: 4%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.middle2-item-msg1-sm {
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;
}

.middle2-item-msg1-sm .number {
  color: #004bc7;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 40px;
  margin-right: 8px;
}

.middle2-item-msg1-sm .unit {
  color: #666666;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
}

.middle2-item-msg2-sm {
  color: #333333;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
}

.middle-hr {
  height: 10px;
  background-color: #f5f5f5;
}

.middle3-content {
  display: flex;
  justify-content: center;
}

.middle3-tab {
  height: 40px;
  border: 1px solid #004bc7;
  box-sizing: border-box;
  margin: 20px 0;
  display: flex;
}

.middle3-tab-item {
  height: 40px;
  color: #333333;
  font-size: 12px;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background-color: #f1f1f1;
  box-sizing: border-box;
  line-height: 40px;
  cursor: pointer;
  padding: 0;
  margin-top: 20px;
}

.middle3-tab-actived {
  color: #ffffff;
  background-color: #004bc7;
}

@media (min-width: 768px) {
  .middle3-tab {
    height: 64px;
    border: 1px solid #004bc7;
    box-sizing: border-box;
    margin: 80px auto 40px;
    display: flex;
  }

  .middle3-tab-item {
    height: 62px;
    color: #333333;
    font-size: 18px;
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 62px;
    cursor: pointer;
    padding: 0 40px;
    margin-top: 0px;
  }

  .middle3-tab-actived {
    color: #ffffff;
    background-color: #004bc7;
  }
}

.exhibition-content1 {
  display: flex;
  margin-bottom: 60px;
}

.exhibition-content1-img {
  width: 600px;
}

.exhibition-content1-img img {
  width: 100%;
}

.exhibition-content1-msg {
  width: 600px;
  color: #333333;
  font-size: 16px;
  background-color: #f5f5f5;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 36px;
  box-sizing: border-box;
  padding: 20px 20px 0;
}

.exhibition-content1-sm {
  margin: 20px 0 16px;
}

.exhibition-content1-img-sm img {
  width: 100%;
}

.exhibition-content1-msg-sm {
  color: #333333;
  font-size: 14px;
  background-color: #f5f5f5;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 26px;
  box-sizing: border-box;
  padding: 20px 16px;
}

.exhibition-content2 {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  margin: 80px 0 30px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.exhibition-content2-img {
  width: 782px;
  height: 543px;
  box-sizing: border-box;
  display: flex;
}

.exhibition-content2-img img {
  width: 100%;
}

.exhibition-content2-right {
  box-sizing: border-box;
  padding: 0 70px;
  flex: 1;
}

.exhibition-content2-msg {
  color: #004bc7;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 27px;
  box-sizing: border-box;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
}

.exhibition-content2-right-img {
  margin-right: 20px;
}

.exhibition-content2-sm {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  padding: 8px 5px;
}

.exhibition-content2-img-sm img {
  width: 100%;
}

.exhibition-content2-bottom-sm {
  color: #004bc7;
  height: 27px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  display: flex;
  justify-content: center;
  margin: 23px 0 19px;
}

.exhibition-content2-bottom-img-sm {
  margin-right: 19px;
}

.exhibition-content3 {
  margin: 30px 0 80px;
  display: flex;
  justify-content: space-between;
}

.exhibition-content3-item {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.exhibition-content3-item-img {
  width: 380px;
  height: 253px;
  display: flex;
}

.exhibition-content3-item-img img {
  width: 100%;
}

.exhibition-content3-item-title {
  color: #333333;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 27px;
  margin-top: 20px;
}

.exhibition-content3-sm {
  margin-bottom: 30px;
}

.exhibition-content3-item-sm {
  margin-top: 20px;
}

.exhibition-content3-item-img-sm img {
  width: 100%;
}

.exhibition-content3-item-title-sm {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  text-align: center;
  line-height: 27px;
  margin-top: 12px;
}

.middle5-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}

.middle5-content-left {
  width: 345px;
}

.middle5-content-msg {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  margin-top: 60px;
}

.middle5-content-msg div {
  margin-bottom: 32px;
}

.middle5-content-sm {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background-color: #f5f5f5;
  line-height: 26px;
  margin: 20px 0;
  padding: 20px 16px;
}

.middle5-content-img-sm {
  margin-top: 20px;
  display: flex;
}

.middle5-content-img-sm img {
  width: 100%;
}

.middle6 {
  height: 728px;
  background: url("../assets/img/about-img5-bg.png") no-repeat center;
  box-sizing: border-box;
  padding: 80px 0;
}

.middle6-content {
  display: flex;
}

.middle6-content-right {
  flex: 1;
}

.middle6-content-tips {
  height: 53px;
  color: #333333;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 27px;
  margin: 8px 0 14px;
  display: flex;
  justify-content: flex-end;
}

.middle6-content-tips .hr-1 {
  width: 200px;
  border-top: 1px solid #333333;
  margin: 14px 20px 0 0;
  position: relative;
}

.middle6-content-tips .hr-2 {
  width: 49px;
  border-top: 1px solid #333333;
  transform: rotate(135deg);
  position: absolute;
  top: 16px;
  left: -41px;
}

.middle6-sm {
  background: url("../assets/img/about-img5-bg.png") no-repeat center top;
  box-sizing: border-box;
  padding: 20px 0;
}

.middle6-content-sm {
  display: flex;
}

.middle6-content-left-sm img {
  width: 100%;
}

.middle6-content-right-sm {
  box-sizing: border-box;
  padding-left: 40px;
  flex: 1;
}

.middle6-content-tips-sm {
  height: 53px;
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
  margin: 10px 0;
}

.middle6-content-tips-sm .hr-1 {
  width: 120px;
  border-top: 1px solid #333333;
  margin: 14px 20px 0 0;
  position: relative;
}

.middle6-content-tips-sm .hr-2 {
  width: 30px;
  border-top: 1px solid #333333;
  transform: rotate(135deg);
  position: absolute;
  top: 10px;
  left: -26px;
}

.middle6-content-table {
  color: #333333;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-align: center;
  border: 1px solid #bbbbbb;
  line-height: 16px;
}

.middle6-table-header {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 20px;
}

.middle6-table-item {
  display: flex;
}

.middle6-table-item + .middle6-table-item {
  border-top: 1px solid #bbbbbb;
}

.middle6-table-item-type {
  width: 50%;
  padding: 2px;
  border-right: 1px solid #bbbbbb;
}

.middle6-table-item-number {
  width: 25%;
  padding: 6px;
  border-right: 1px solid #bbbbbb;
}

.middle6-table-item-size {
  width: 25%;
  padding: 6px;
}

@media (min-width: 768px) {
  .middle6-content-table {
    color: #333333;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-align: center;
    border: 1px solid #bbbbbb;
    line-height: 18px;
  }

  .middle6-table-header {
    color: #333333;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 24px;
  }

  .middle6-table-item {
    display: flex;
  }

  .middle6-table-item + .middle6-table-item {
    border-top: 1px solid #bbbbbb;
  }

  .middle6-table-item-type {
    width: 50%;
    padding: 6px;
    border-right: 1px solid #bbbbbb;
  }

  .middle6-table-item-number {
    width: 25%;
    padding: 6px;
    border-right: 1px solid #bbbbbb;
  }

  .middle6-table-item-size {
    width: 25%;
    padding: 6px;
  }
}

.middle7 {
  padding-top: 80px;
}

.middle7-content {
  display: flex;
  justify-content: space-between;
  padding-bottom: 80px;
}

.middle7-content-msgs1 {
  width: 440px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.middle7-msgs-title {
  position: relative;
}

.middle7-msgs-title span {
  width: 64px;
  height: 64px;
  background-color: #d6f2ff;
  display: inline-block;
}

.middle7-msgs-title-text {
  width: 100%;
  color: #333333;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  box-sizing: border-box;
  line-height: 50px;
  padding-left: 15px;
  position: absolute;
  top: 5px;
  left: 0;
}

.middle7-content-msgs1-text {
  width: 100%;
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  word-wrap: break-word;
  line-height: 21px;
  margin-top: 20px;
  padding: 28px 33px;
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.middle7-content-msgs1-text .msg {
  margin-top: 10px;
}

.middle7-msgs1-text-blue {
  color: #004bc7;
  display: inline-block;
}

.middle7-msgs1-text-blue span {
  font-size: 24px;
  font-weight: 700;
}

.middle7-content-msgs2-text {
  width: 240px;
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  word-wrap: break-word;
  line-height: 26px;
  margin-top: 20px;
  padding: 30px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.middle7-content-msgs2-text .msg {
  margin-top: 10px;
}

.middle7-msgs2-text-blue {
  color: #004bc7;
}

.middle7-msgs2-text-blue span {
  font-size: 24px;
  font-weight: 700;
}

.middle7-sm {
  margin: 20px 0;
}

.middle7-content-img-sm {
  margin: 20px 0;
}

.middle7-content-img-sm img {
  width: 100%;
}

.middle7-msgs-title-sm {
  margin: 20px 0;
  padding-top: 10px;
  position: relative;
}

.middle7-msgs-title-sm span {
  width: 100px;
  height: 10px;
  background-color: #d6f2ff;
  display: inline-block;
}

.middle7-msgs-title-text-sm {
  width: 100%;
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: 4px;
  left: 10px;
}

.middle7-content-msgs1-text-sm {
  color: #333333;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 26px;
  box-sizing: border-box;
}

.middle7-content-msgs1-text-sm div + div {
  margin-top: 10px;
}

.middle7-msgs1-text-blue-sm {
  color: #004bc7;
  display: inline-block;
}

.middle7-msgs1-text-blue-sm span {
  font-size: 24px;
}

.middle8-content {
  display: flex;
  margin-bottom: 80px;
}

.middle8-content-img {
  width: 70%;
  display: flex;
}

.middle8-content-img img {
  width: 100%;
}

.middle8-content-msgs {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
  background-color: #f1f1f1;
  box-sizing: border-box;
  padding: 80px 50px;
  flex: 1;
}

.middle8-content-msgs .msg1 {
  padding: 10px 0;
}

.middle8-msgs-text-blue {
  color: #004bc7;
  display: inline-block;
}

.middle8-msgs-text-blue span {
  font-size: 24px;
  font-weight: 700;
}

.middle8-content-msgs .msg3 {
  margin: 48px 0 10px;
}

.middle8-content-msgs .msg4 {
  font-size: 24px;
  font-weight: 700;
}

.middle8-sm {
  margin: 20px 0;
}

.middle8-img-sm img {
  width: 100%;
}

.middle8-content-sm {
  color: #333333;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background-color: #f5f5f5;
  box-sizing: border-box;
  line-height: 20px;
  margin-bottom: 20px;
  padding: 10px;
}

.middle8-sm-content div + div {
  margin-top: 20px;
}

.middle8-sm-text-blue {
  color: #004bc7;
  display: inline-block;
}

.middle8-sm-text-blue span {
  font-size: 24px;
}

.middle8-sm-phone {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border-top: 1px solid #eeeeee;
  line-height: 19px;
  margin: 20px 0;
  padding-top: 16px;
  display: flex;
}

.middle8-sm-phone div:first-child {
  margin-right: 10px;
}

.middle8-sm-hotel-btn {
  color: #004bc7;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  cursor: pointer;
  margin-left: 26px;
}

.meeting1-content-msg {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 40px;
}

.meeting1-content-img {
  display: flex;
  margin-bottom: 60px;
}

.meeting1-content-img img {
  width: 100%;
}

.meeting1-content {
  width: 100%;
  display: flex;
  margin-bottom: 80px;
}

.meeting1-content .pl-0 {
  padding-left: 0;
}

.meeting1-item {
  width: 20%;
  box-sizing: border-box;
  padding-left: 80px;
}

.meeting1-item + .meeting1-item {
  border-left: 1px solid #eeeeee;
}

.meeting1-item-title {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 10px;
}

.meeting1-item-hr {
  width: 100px;
  height: 2px;
  background-color: #999999;
  margin-bottom: 16px;
}

.meeting1-item-number {
  color: #004bc7;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background-color: #ebf3ff;
  line-height: 19px;
  margin-bottom: 10px;
  padding: 4px 10px;
  display: inline-block;
}

.meeting1-item-area {
  color: #004bc7;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
}

.meeting1-item-area span {
  color: #004bc7;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 40px;
}

.meeting1-sm-content {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background-color: #f5f5f5;
  line-height: 26px;
  margin: 20px 0;
  padding: 20px 16px;
}

.meeting1-content-img-sm {
  margin-top: 20px;
  display: flex;
}

.meeting1-content-img-sm img {
  width: 100%;
}

.meeting1-item-sm {
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 10px;
}

.meeting1-item-title-sm {
  color: #333333;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 8px;
}

.meeting1-item-hr-sm {
  width: 100%;
  height: 2px;
  background-color: #999999;
  margin-bottom: 8px;
}

.meeting1-item-number-sm {
  color: #004bc7;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background-color: #ebf3ff;
  line-height: 19px;
  margin-bottom: 10px;
  padding: 4px 10px;
  display: inline-block;
}

.meeting1-item-area-sm {
  color: #004bc7;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
}

.meeting1-item-area-sm span {
  color: #004bc7;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24px;
}

.meeting2-sm {
  padding-top: 20px;
}

.meeting2-content1 {
  display: flex;
}

.meeting2-content1-img {
  width: 50%;
  display: flex;
}

.meeting2-content1-img img {
  width: 100%;
}

.meeting2-content1-right {
  width: 50%;
  box-sizing: border-box;
  padding-left: 50px;
}

.meeting2-content1-right-content {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  padding: 40px 0;
}

.meeting2-content1-right-txt {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
}

.meeting2-content1-right-txt + .meeting2-content1-right-txt {
  margin-top: 40px;
}

.meeting2-content2 {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}

.meeting2-content2-left {
  padding: 0 50px;
  box-sizing: border-box;
  flex: 1;
}

.meeting2-content2-left-img {
  margin-right: 50px;
}

.meeting2-content1-left-content {
  flex: 1;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  padding: 40px 0;
}

.meeting2-content1-left-msg {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
}

.meeting2-content2-img {
  width: 420px;
  display: flex;
}

.meeting2-content2-img img {
  width: 100%;
}

.meeting2-content3 {
  margin-bottom: 80px;
  display: flex;
}

.meeting2-content3-img {
  width: 50%;
  display: flex;
}

.meeting2-content3-img img {
  width: 100%;
}

.meeting2-content3-right {
  width: 50%;
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background-color: #f5f5f5;
  line-height: 32px;
  padding: 150px 80px 0;
}

.meeting2-img-sm {
  margin: 20px;
}

.meeting2-img-sm img {
  width: 100%;
}

.meeting2-msg-sm {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 26px;
  margin: 20px;
}

.holidayInn-middle-content-item {
  display: flex;
}

.holidayInn-middle-content-img {
  display: flex;
}

.holidayInn-middle-content-img img {
  width: 100%;
}

.holidayInn-middle-content-txt {
  box-sizing: border-box;
  padding-top: 0px;
  flex: 1;
}

.holidayInn-middle-content-msg {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 28px;
}

.holidayInn-middle-content-title {
  display: flex;
}

.holidayInn-middle-content-title-msg {
  width: 200px;
  height: 44px;
  color: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  background-color: #004bc7;
  box-sizing: border-box;
  line-height: 44px;
  padding-left: 20px;
}

.middle-item-triangle {
  width: 0;
  height: 0;
  border-bottom: 44px solid #004bc7;
  border-right: 44px solid transparent;
}

.holidayInn-middle-content-txt-msg {
  color: #333333;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  margin-top: 4px;
}

.holidayInn-middle-content-txt-msg span {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #00a3ff;
  margin-right: 10px;
  display: inline-block;
}

@media (min-width: 768px) {
  .holidayInn-middle-content-txt {
    box-sizing: border-box;
    padding-top: 40px;
    flex: 1;
  }

  .holidayInn-middle-content-msg {
    color: #333333;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 32px;
  }

  .holidayInn-middle-content-title {
    display: flex;
  }

  .holidayInn-middle-content-title-msg {
    width: 200px;
    height: 50px;
    color: #ffffff;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    background-color: #004bc7;
    box-sizing: border-box;
    line-height: 50px;
    padding-left: 20px;
  }

  .middle-item-triangle {
    width: 0;
    height: 0;
    border-bottom: 50px solid #004bc7;
    border-right: 50px solid transparent;
  }

  .holidayInn-middle-content-txt-msg {
    color: #333333;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 28px;
    margin-top: 8px;
  }

  .holidayInn-middle-content-txt-msg span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #00a3ff;
    margin-right: 20px;
    display: inline-block;
  }
}

.holidayInn-middle-box-sm {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background-color: #f5f5f5;
  line-height: 26px;
  margin: 20px 0;
  padding: 20px 16px;
}

.holidayInn-middle-content-img-sm {
  margin-top: 20px;
}

.holidayInn-middle-content-img-sm img {
  width: 100%;
}

.holidayInn-middle2 {
  margin: 80px 0;
  display: flex;
}

.holidayInn-middle2-img {
  width: 50%;
}

.holidayInn-middle2-img img {
  width: 100%;
}

.holidayInn-middle2-content {
  flex: 1;
  box-sizing: border-box;
  background-color: #f1f1f1;
  padding: 100px 50px 0 50px;
}

.holidayInn-middle2-content-msg {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
}

.holidayInn-middle2-sm {
  padding: 20px 0;
}

.holidayInn-middle2-img-sm {
  display: flex;
}

.holidayInn-middle2-img-sm img {
  width: 100%;
}

.holidayInn-middle2-content-msg-sm {
  color: #ffffff;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background-color: #004bc7;
  line-height: 26px;
  padding: 20px;
}

.holidayInn-middle2-content-msg2-sm {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 26px;
  padding-top: 20px;
}

.holidayInn-middle3-img {
  width: 600px;
  display: flex;
}

.holidayInn-middle3-img img {
  width: 100%;
}

.gym {
  height: 230px;
  background: #f1f1f1 url("../assets/img/about-bg-img4.png") no-repeat center;
  box-sizing: border-box;
  margin: 80px 0;
  padding: 80px 60px 0;
  display: flex;
}

.gym-title {
  width: 240px;
}

.gym-content {
  color: #666666;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 28px;
  flex: 1;
  display: flex;
}

.gym-content span {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #00a3ff;
  margin: 10px 10px 0 0;
  display: inline-block;
}

.gym-content div {
  flex: 1;
}

.gym-sm {
  background: url("../assets/img/about-bg-img4.png") no-repeat center;
  padding: 40px 20px;
}

@media (min-width: 768px) {
  .gym-content {
    color: #666666;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 32px;
    flex: 1;
    display: flex;
  }

  .gym-content span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #00a3ff;
    margin: 12px 20px 0 0;
    display: inline-block;
  }

  .gym-content div {
    flex: 1;
  }
}

.hall-box {
  margin: 20px 0;
}

.hall-box-img img {
  width: 100%;
}

.hall-box-msg {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  background-color: #f5f5f5;
  line-height: 26px;
  padding: 20px 16px;
}

@media (min-width: 768px) {
  .hall-box {
    margin: 40px 0 80px;
  }

  .hall-box-img {
    width: 600px;
  }

  .hall-box-img img {
    width: 100%;
  }

  .hall-box-msg {
    color: #333333;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    background-color: #f5f5f5;
    box-sizing: border-box;
    line-height: 36px;
    flex: 1;
    padding: 20px 30px 0;
  }

  .hall-box-msg1 {
    color: #333333;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    background-color: #f5f5f5;
    box-sizing: border-box;
    line-height: 36px;
    flex: 1;
    padding: 0px 30px 20px;
  }
}
</style>
